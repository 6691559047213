import React, { useContext } from 'react';
import { navigate, Link } from 'gatsby';
import { css } from '@emotion/react';
import * as dayjs from 'dayjs';
import { ThemeContext } from './theme-provider';

export default function MobileHeader({ reference }) {
  const theme = useContext(ThemeContext);

  return (
    <div
      ref={reference}
      className="type--60"
      css={css`
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        font-size: 1.5rem;
        line-height: 1;
        z-index: 100;
        cursor: pointer;
        transform: ${(theme.smallHeader) ? 'translateY(-2.8rem)' : 'translateY(0)' };
        transition: transform 0.6s var(--curve);

        > div {
          padding: calc(var(--gutter) * 0.75) var(--gutter);
        }

        @media (max-width: 1080px) {
          display: flex;
        }

        @media (max-width: 700px) {
          font-size: 2rem;
          transform: ${(theme.smallHeader) ? 'translateY(-3.7rem)' : 'translateY(0)' };

          > div {
            padding: calc(var(--gutter) * 1) var(--gutter) calc(var(--gutter) * 0.75);
          }

          a:hover {
            color: var(--black);
          }
        }

      `}
    >
      <div
        css={css`
          position: relative;
          flex: 0 0 50%;
          background-color: var(--white);
        `}
        onClick={() => {
          theme.setMenuOpen(false);
          theme.setCalendarOpen(false);
          navigate('/');
        }}
      >
        The Substation/
      </div>
      <div
        css={css`
          flex: 0 0 25%;
          background-color: var(--gray);
        `}
        onClick={() => {
          if (theme.menuOpen) {
            theme.setMenuOpen(false);
            theme.setCalendarOpen(false);
          } else {
            theme.setMenuOpen(true);
            theme.setCalendarOpen(false);
          }
        }}
      >
        Menu/
      </div>
      <div
        css={css`
          flex: 0 0 25%;
          background-color: var(--primary);
        `}
        onClick={() => {
          if (theme.calendarOpen) {
            theme.setMenuOpen(false);
            theme.setCalendarOpen(false);
          } else {
            theme.setMenuOpen(false);
            theme.setCalendarOpen(true);
          }
        }}
      >
        {dayjs().format('MMM')}/
      </div>
    </div>
  )
}
