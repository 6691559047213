// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-hire-js": () => import("./../../../src/pages/hire.js" /* webpackChunkName: "component---src-pages-hire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-visit-js": () => import("./../../../src/pages/visit.js" /* webpackChunkName: "component---src-pages-visit-js" */),
  "component---src-pages-whats-on-js": () => import("./../../../src/pages/whats-on.js" /* webpackChunkName: "component---src-pages-whats-on-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-program-js": () => import("./../../../src/templates/program.js" /* webpackChunkName: "component---src-templates-program-js" */),
  "component---src-templates-publication-js": () => import("./../../../src/templates/publication.js" /* webpackChunkName: "component---src-templates-publication-js" */),
  "component---src-templates-venue-js": () => import("./../../../src/templates/venue.js" /* webpackChunkName: "component---src-templates-venue-js" */)
}

