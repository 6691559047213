import React, { useContext, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { css } from '@emotion/react';
import PortableText from '../components/portable-text';
import { ThemeContext } from './theme-provider';
import Close from '../svg/close.svg';

const MODAL_DELAY = 10000;
const ONE_WEEK_IN_MS = 10 * 24 * 60 * 60 * 1000;
const LAST_DISPLAYED_KEY = 'modalNewsletterLastDisplayed';

export default function ModalNewsletterAuto() {
  const [isVisible, setIsVisible] = useState(false);

  const data = useStaticQuery(graphql`
    query NewsletterQuery {
      siteSettings: sanitySiteSettings(_id: { regex: "/(drafts\\\\.)?siteSettings/" }) {
        _rawNewsletter(resolveReferences: {maxDepth: 10})
      }
    }
  `)

  useEffect(() => {
    const lastDisplayed = localStorage.getItem(LAST_DISPLAYED_KEY);
    const now = Date.now();

    if (!lastDisplayed || now - lastDisplayed > ONE_WEEK_IN_MS) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, MODAL_DELAY);
      
      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem(LAST_DISPLAYED_KEY, Date.now());
  };

  if (!isVisible) return null;

  return (
    <div
      css={css`
        display: ${isVisible ? 'block' : 'none'};
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
        pointer-events: none;
      `}
    >
      <div
        className="type--45"
        css={css`
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          padding: var(--gutter);
        `}
      >
        <div
          css={css`
            position: relative;
            width: 100%;
            max-width: 870px;
            color: var(--black);
            background-color: var(--white);
            padding: calc(var(--gutter) * 2);
            pointer-events: auto;
            border: 3px solid var(--black);
            box-shadow: 0px 0px 10rem rgba(0, 0, 0, 0.125);
          `}
        >
          <div
            css={css`
              position: absolute;
              top: calc(var(--gutter) * 2);
              right: calc(var(--gutter) * 2);
              cursor: pointer;

              &:hover {
                svg {
                  circle {
                    stroke: var(--primary);
                    transition: stroke 0s;
                  }

                  path {
                    stroke: var(--primary);
                    transition: stroke 0s;
                  }
                }
              }

              svg {
                @media (max-width: 700px) {
                  width: 6rem;
                }

                circle {
                  stroke: var(--black);
                  transition: stroke 0.6s var(--curve);
                }

                path {
                  stroke: var(--black);
                  transition: stroke 0.6s var(--curve);
                }
              }
            `}
            onClick={handleClose}
          >
            <Close />
          </div>
          <div
            css={css`
              padding-right: calc(var(--gutter) * 4);
              margin-bottom: calc(var(--gutter) * 2);
            `}
          >
            <PortableText
              blocks={data.siteSettings._rawNewsletter}
            />
          </div>
          <form
            className="type--30"
            css={css`
              display: flex;
            `}
            action="//thesubstation.us5.list-manage.com/subscribe/post?u=21cfc09295377f72f03e377c6&amp;id=f0c98b414b"
            method="post"
          >
            <input
              css={css`
                display: block;
                flex-grow: 1;
                width: auto;
                padding: 2rem 1.5rem;
                border-radius: 0;
                -webkit-appearance: none;
                border: 3px solid var(--black);
                margin-right: var(--gutter);

                @media (max-width: 700px) {
                  width: 100%;
                }
              `}
              type="email"
              name="EMAIL"
              placeholder="enter your email here"
            />
            <input
              css={css`
                display: block;
                border-radius: 0;
                -webkit-appearance: none;
                border: 3px solid var(--black);
                background-color: transparent;
                padding: 1rem 4rem 0.7rem;
                margin: 0 auto;
                cursor: pointer;
                transition: background-color 0.6s var(--curve);

                &:hover {
                  background-color: var(--primary);
                  transition: background-color 0s;
                }
              `}
              type="submit"
              value="Subscribe"
              name="subscribe"
            />
          </form>
        </div>
      </div>
    </div>
  );
}
