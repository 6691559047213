import React  from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { css } from '@emotion/react';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import Image from 'gatsby-plugin-sanity-image';
import ConditionalWrapper from './conditional-wrapper';

export default class PortableText extends React.Component {
  constructor(props) {
    super(props);

    function getImage(id, width) {
      return getGatsbyImageData(id, { width: width }, {
        projectId: process.env.SANITY_PROJECT_ID,
        dataset: process.env.SANITY_PROJECT_DATASET,
      })?.images.fallback.src;
    }

    this.footnoteCount = 0;

    this.serializers = {
      marks: {
        footnote: ({ children, mark }) => {
          this.footnoteCount++;

          return (
            <span>{children}<sup
              css={css`
                color: var(--primary);
                font-size: 0.6em;
              `}
            >{this.footnoteCount}</sup></span>
          )
        },
        link: ({ children, mark }) => {
          const link = (mark.url) ? mark.url : mark.href;

          return (
            <a href={link}>{children}</a>
          )
        },
      },
      types: {
        block: (props) => {
          if (props.node.style === 'indent') {
            return (
              <p
                className="portable-text--indent"
                css={css`
                  padding-left: calc(
                    (((100vw - (var(--margin) * 2)) - (var(--gutter) * 11)) / 12)
                    * 1.5 + (1.5 * var(--gutter))
                  );
                `}
              >{props.children}</p>
            )
          } else if (props.node.style === 'center') {
            return (
              <p
                className="portable-text--center"
                css={css`
                  text-align: center;
                  padding: 0 calc(
                    (((100vw - (var(--margin) * 2)) - (var(--gutter) * 11)) / 12)
                    * 1.5 + (1.5 * var(--gutter))
                  );
                `}
              >{props.children}</p>
            )
          } else {
            return BlockContent.defaultSerializers.types.block(props);
          }
        },
        image: ({ node }) => (
          <div
            className="portable-text--image"
            css={css`
            `}
          >
            <img
              css={css`
                width: 100%;
              `}
              src={getImage(node.asset._id, 1980)}
            />
            {node.asset.description && <div
              className="type--15"
              css={css`
                padding: var(--gutter) var(--margin);
              `}
            >{node.asset.description}
            </div>}
          </div>
        ),
        richImage: ({ node }) => (
          <div
            css={css`
              display: ${(node.inline) ? 'inline-block' : 'block'};
              height: ${(node.inline) ? '8rem' : 'auto'};
              margin-top: ${(node.inline) ? '2rem' : 0};
              margin-right: ${(node.inline) ? '5rem' : 0};
            `}
          >
            {node.image && <ConditionalWrapper
              condition={node.link}
              wrapper={children => <a target="_blank" rel="noreferrer" href={node.link}>{children}</a>}
            >
              {node.image && <Image
                {...node.image}
                width={(node.inline) ? 990 : 1980}
                style={{
                  maxWidth: (node.inline) ? '20rem' : 'none',
                  maxHeight: '100%',
                  verticalAlign: 'middle',
                }}
                loading="eager"
              />}
            </ConditionalWrapper>}
          </div>
        ),
      },
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.blocks !== nextProps.blocks) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <BlockContent
        className={this.props.className}
        blocks={this.props.blocks}
        serializers={this.serializers}
        renderContainerOnSingleChild
      />
    )
  }
}
