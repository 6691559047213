import React, { useContext } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { css } from '@emotion/react';
import { ThemeContext } from './theme-provider';
import PortableText from '../components/portable-text';
import { isOpen, convertDay, formatTimes } from '../utility';
import ArrowRight from '../svg/arrow-right.svg';

export default function Menu({ reference }) {
  const data = useStaticQuery(graphql`
    query SiteSettingsQuery {
      siteSettings: sanitySiteSettings(_id: { regex: "/(drafts\\\\.)?siteSettings/" }) {
        _rawAddress(resolveReferences: {maxDepth: 10})
        openHours {
          startDay
          endDay
          startTime
          endTime
        }
        closedDates {
          startDate
          endDate
        }
        _rawContact(resolveReferences: {maxDepth: 10})
        _rawSocials(resolveReferences: {maxDepth: 10})
        _rawAcknowledgement(resolveReferences: {maxDepth: 10})
        _rawSupporters(resolveReferences: {maxDepth: 10})
      }
    }
  `)

  const theme = useContext(ThemeContext);

  return (
    <footer
      css={css`
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--${(theme.grayFooter) ? 'lightGray' : 'white'}) 10rem, var(--${(theme.grayFooter) ? 'lightGray' : 'white'}) 100%);
        padding: 15rem 0 0;
      `}
    >
      <div
        className="type--70"
        css={css`
          text-align: center;
          padding: 20rem var(--margin);
          cursor: pointer;
          transition: color 0.6s var(--curve);

          &:hover {
            color: var(--primary);
            transition: color 0s;

            svg path {
              stroke: var(--primary);
              transition: stroke 0s;
            }
          }

          svg {
            height: 5rem;

            path {
              transition: stroke 0.6s var(--curve);
            }
          }
        `}
        onClick={() => theme.setShowSubscription(true)}
      >
        Sign up to our<br /> newsletter&nbsp;<ArrowRight />
      </div>
      <div
        className="type--20"
        css={css`
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          gap: var(--gutter);
          color: var(--white);
          background-color: var(--black);
          padding: var(--margin);

          a {
            color: var(--white);
          }
        `}
      >
        <div
          css={css`
            grid-column: span 4;

            @media (max-width: 700px) {
              grid-column: span 12;
              margin: 5rem 0;
            }
          `}
        >
          <Link to="/">
            <img
              css={css`
                width: 100%;
                max-width: 17rem;

                @media (max-width: 700px) {
                  max-width: 25rem;
                }
              `}
              src="/images/logo.gif"
              alt="The Substation"
            />
          </Link>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            grid-column: span 4;

            @media (max-width: 700px) {
              grid-column: span 12;
              margin-bottom: 5rem;
            }
          `}
        >
          <div>
            <PortableText
              blocks={data.siteSettings._rawAddress}
              css={css`
                margin-bottom: 2rem;
              `}
            />
            Currently {(isOpen(data.siteSettings.openHours, data.siteSettings.closedDates)) ? <span css={css`color: var(--primary);`}>open</span> : 'closed'}
            {data.siteSettings.openHours.length > 0 && data.siteSettings.openHours
              .map((time, i) => (
                <div key={i}>
                  {(time.endDay) ? `${convertDay(time.startDay)}–${convertDay(time.endDay)}` : convertDay(time.startDay)} {formatTimes(time.startTime, time.endTime)}
                </div>
              ))
            }
            <PortableText
              css={css`
                margin-bottom: 2rem;
              `}
              blocks={data.siteSettings._rawContact}
            />
          </div>
          <PortableText
            blocks={data.siteSettings._rawSocials}
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            grid-column: span 4;

            @media (max-width: 700px) {
              grid-column: span 12;
            }
          `}
        >
          <PortableText
            css={css`
              margin-bottom: 6rem;
            `}
            blocks={data.siteSettings._rawAcknowledgement}
          />
          <PortableText
            blocks={data.siteSettings._rawSupporters}
          />
          <div
            css={css`
              margin-top: 3rem;
            `}
          >
            &copy; {new Date().getFullYear()} Substation. Website by <a href="https://basedesign.com/" target="_blank">Base Design</a>.
          </div>
        </div>
      </div>
    </footer>
  )
}
