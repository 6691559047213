import * as dayjs from 'dayjs';

export function isOpen(dates, exceptions) {
  let isOpen = false;

  const days = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };

  dates.forEach((date) => {
    if (date.endDay) {
      let count;
      if (days[date.startDay] > days[date.endDay]) {
        count = days[date.endDay] + 7 - days[date.startDay] + 1;
      } else {
        count = days[date.endDay] - days[date.startDay] + 1;
      }
     
      for (let i = 0; i < count; i++) {
        let day;
        if (days[date.startDay] + i > 7) {

          day = days[date.startDay] + i - 7;
        } else {
          day = days[date.startDay] + i;
        }

        if (checkDay(day)) {
          if (checkTime(parseInt(pad(date.startTime.replace(':', '')), 10), parseInt(pad(date.endTime.replace(':', '')), 10))) {
            isOpen = true;
          }
        }
      }
    } else {
      if (checkDay(days[date.startDay])) {
        if (checkTime(parseInt(pad(date.startTime.replace(':', '')), 10), parseInt(pad(date.endTime.replace(':', '')), 10))) {
          isOpen = true;
        }
      }
    }
  });

  exceptions.forEach((dateRange) => {
    if (dateRange.endDate) {
      for (let d = new Date(dateRange.startDate); d <= new Date(dateRange.endDate); d.setDate(d.getDate() + 1)) {
        if (sameDay(d, new Date())) isOpen = false;
      }
    } else {
      if (sameDay(new Date(dateRange.startDate), new Date())) isOpen = false;
    }
  });

  return isOpen;

  function checkDay(day) {
    if (new Date().getDay() === day) {
      return true;
    } else {
      return false;
    }
  }

  function checkTime(start, end) {
    const current = parseInt(`${pad(new Date().getHours())}${pad(new Date().getMinutes())}`, 10);
    if (current >= start && current <= end) return true;
  }

  function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  function pad(value) {
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  }
}

export function convertDay(day) {
  switch (day) {
    case 'monday':
      return 'Mon';
    case 'tuesday':
      return 'Tue';
    case 'wednesday':
      return 'Wed';
    case 'thursday':
      return 'Thu';
    case 'friday':
      return 'Fri';
    case 'saturday':
      return 'Sat';
    case 'sunday':
      return 'Sun';
    default:
      return '';
  }
}

export function formatTimes(startTime, endTime) {
  let timeString = '';

  startTime = parseInt(startTime?.replace(':', ''), 10);
  endTime = parseInt(endTime?.replace(':', ''), 10);

  if (startTime < 1200 && endTime < 1200) {
    // Both AM
    timeString = `${formatTime(startTime)}–${formatTime(endTime)}am`;
  } else if (startTime >= 1200 && endTime >= 1200) {
    // Both pm
    timeString = `${formatTime(startTime)}–${formatTime(endTime)}pm`;
  } else {
    timeString = `${formatTime(startTime)}${(startTime < 1200) ? 'am' : 'pm'}–${formatTime(endTime)}${(endTime < 1200) ? 'am' : 'pm'}`;
  }

  return timeString;

  function formatTime(time) {
    if (time % 100 === 0) {
      if (time === 0) {
        return '12';
      } else {
        return normalizeTime(time / 100);
      }
    } else {
      if (time === 30) {
        return '12:30';
      } else if (time < 1000) {
        return `${normalizeTime(time.toString().slice(0, 1))}:${time.toString().slice(1, 3)}`;
      } else {
        return `${normalizeTime(time.toString().slice(0, 2))}:${time.toString().slice(2, 4)}`;
      }
    }
  }

  function normalizeTime(time) {
    time = parseInt(time, 10);

    return (time > 12) ? time - 12 : time;
  }
}

export function formatDates(startDate, endDate) {
  let dateString = '';

  startDate = dayjs(startDate);

  if (!endDate) {
    dateString = startDate.format('D MMMM YYYY');
  } else {
    endDate = dayjs(endDate);

    if (startDate.year() === endDate.year()) {
      if (startDate.month() === endDate.month()) {
        dateString = `${startDate.format('D')}–${endDate.format('D MMMM YYYY')}`;
      } else {
        dateString = `${startDate.format('D MMMM')}–${endDate.format('D MMMM YYYY')}`;
      }
    } else {
      dateString = `${startDate.format('D MMMM YYYY')}–${endDate.format('D MMMM YYYY')}`;
    }
  }
  
  return dateString;
}
