import React, { useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { css, Global } from '@emotion/react';
import * as normalize from '../styles/normalize.css';
import * as global from '../styles/global.css';
import { ThemeContext } from './theme-provider';
import FadeTransition from './fade-transition';
import SEO from '../components/seo';
import MobileHeader from './mobile-header';
import Menu from './menu';
import Calendar from './calendar';
import Footer from './footer';
import ModalGallery from './modal-gallery';
import ModalContent from './modal-content';
import ModalNewsletter from './modal-newsletter';
import ModalNewsletterAuto from './modal-newsletter-auto';
import { isOpen } from '../utility';

export default function Layout({ children, location }) {
  const [mainScale, setMainScale] = useState(1);
  const [mainTranslate, setMainTranslate] = useState(0);

  const mainRef = useRef(null);
  const menuRef = useRef(null);
  const calendarRef = useRef(null);

  const theme = useContext(ThemeContext);

  const data = useStaticQuery(graphql`
    query SiteLayoutQuery {
      siteSettings: sanitySiteSettings(_id: { regex: "/(drafts\\\\.)?siteSettings/" }) {
        openHours {
          startDay
          endDay
          startTime
          endTime
        }
        closedDates {
          startDate
          endDate
        }
      }
    }
  `)

  useLayoutEffect(() => {
    if (theme.menuOpen && theme.calendarOpen) {
      setMainScale(1 - menuRef.current.offsetWidth / window.innerWidth - calendarRef.current.offsetWidth / window.innerWidth);
      setMainTranslate(menuRef.current.offsetWidth / 2 - calendarRef.current.offsetWidth / 2);
    } else if (theme.menuOpen) {
      setMainScale(1 - menuRef.current.offsetWidth / window.innerWidth);
      setMainTranslate(menuRef.current.offsetWidth / 2);
    } else if (theme.calendarOpen) {
      setMainScale(1 - calendarRef.current.offsetWidth / window.innerWidth);
      setMainTranslate(-calendarRef.current.offsetWidth / 2);
    } else {
      setMainScale(1);
      setMainTranslate(0);
    }
  }, [theme.menuOpen, theme.calendarOpen]);

  useEffect(() => {
    let previousScrollPos = 0;

    document.addEventListener('scroll', (e) => {
      const scrollPos = window.scrollY;

      if (scrollPos <= 100 || scrollPos < previousScrollPos) {
        theme.setSmallHeader(false);
      } else {
        theme.setSmallHeader(true);
      }
      previousScrollPos = scrollPos;
    });
  }, [mainRef]);

  return (
    <div>
      <Global
        styles={css`
          ${normalize}
          ${global}
        `}
      />
      <SEO />
      <MobileHeader />
      <FadeTransition location={location}>
        <main
          ref={mainRef}
          css={css`
            transform: translateX(${mainTranslate}px) scaleX(${mainScale});
            transition: transform 0.6s var(--curve);

            @media (max-width: 1080px) {
              transition: transform 0s;
              padding-top: 2.8rem;
            }

            @media (max-width: 700px) {
              padding-top: 5.5rem;
            }
          `}
        >
          <div
            className="type--15"
            css={css`
              position: absolute;
              top: var(--margin);
              right: var(--margin);
              color: var(--white);
              text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
              z-index: 1;

              @media (max-width: 1080px) {
                top: calc(var(--margin) + 2.8rem);
              }

              @media (max-width: 700px) {
                display: none;
              }

              &::before {
                content: '';
                display: inline-block;
                width: 1.1rem;
                height: 1.1rem;
                border-radius: 50%;
                background-color: var(--white);
                margin-right: 1rem;
              }
            `}
          >
            Currently {(isOpen(data.siteSettings.openHours, data.siteSettings.closedDates)) ? <span css={css`color: var(--primary);`}>open</span> : 'closed'}
          </div>
          {children}
          <Footer />
        </main>
      </FadeTransition>
      <Menu reference={menuRef} />
      <Calendar reference={calendarRef} />
      <ModalGallery />
      <ModalContent />
      <ModalNewsletter />
      <ModalNewsletterAuto />
    </div>
  )
}
