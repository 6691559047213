import React, { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { ThemeContext } from './theme-provider';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { useEmblaCarousel } from 'embla-carousel/react';
import Close from '../svg/close.svg';

export default function ModalGallery() {
  const [backgroundAnimation, setBackgroundAnimation] = useState(null);
  const [backgroundXOffset, setBackgroundXOffset] = useState(0);
  const [backgroundYOffset, setBackgroundYOffset] = useState(0);
  const [description, setDescription] = useState(null);
  const [hideDescription, setHideDescription] = useState(false);

  const emblaOptions = {
    draggable: false,
    speed: 7,
    loop: true,
  };
  const [emblaRef, embla] = useEmblaCarousel(emblaOptions);

  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (theme.showGallery) {
      setBackgroundAnimation(setInterval(() => {
        setBackgroundXOffset(parseInt(Math.random() * (1920), 10));
        setBackgroundYOffset(parseInt(Math.random() * (1080), 10));
      }, 1000 / 16));

      embla?.reInit(emblaOptions);
      embla?.scrollTo(theme.initialGallerySlide, true);
    }
  }, [theme.showGallery]);

  useEffect(() => {
    changeDescription();

    embla?.on('select', (e) => {
      changeDescription();
    })
  }, [embla]);


  function changeDescription() {
    setHideDescription(true);
    setTimeout(() => {
      if (theme.galleryImages[embla?.selectedScrollSnap()]?.asset.description) {
        setDescription(theme.galleryImages[embla?.selectedScrollSnap()]?.asset.description);
        setHideDescription(false);
      }
    }, 600);
  }

  return (
    <div
      css={css`
        display: ${(theme.showGallery) ? 'block' : 'none'};
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        color: var(--white);
        text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
        background-image: url('/images/background-static.png');
        will-change: background-position;
        z-index: 10;
      `}
      style={{
        backgroundPosition: `${backgroundXOffset}px ${backgroundYOffset}px`,
      }}
    >
      <div
        ref={emblaRef}
        css={css`
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          line-height: 0;
          overflow: hidden;
        `}
      >
        <div
          css={css`
            display: flex;
            width: 100%;
            height: 100%;
          `}
        >
          {theme.galleryImages.length > 0 && theme.galleryImages
            .map((image, i) => (
              <img
                key={i}
                css={css`
                  flex-shrink: 0;
                  position: relative;
                  width: calc(100% - (var(--margin) * 2));
                  height: calc(100% - (var(--margin) * 2));
                  object-fit: contain;
                  margin: var(--margin);
                `}
                src={getGatsbyImageData(image?.asset?._id, { width: 1980 }, {
                       projectId: process.env.SANITY_PROJECT_ID,
                       dataset: process.env.SANITY_PROJECT_DATASET,
                     })?.images.fallback?.src}
                onLoad={() => embla?.reInit(emblaOptions)}
              />
            ))
          }
        </div>
      </div>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(12, minmax(0, 1fr));
          gap: var(--gutter);
          position: absolute;
          top: var(--margin);
          right: var(--margin);
          bottom: var(--margin);
          left: var(--margin);
        `}
      >
        <div
          className="type--30"
          css={css`
            position: relative;
            top: -1.8rem;
            grid-column: span 8;
            opacity: ${(hideDescription) ? 0 : 1};
            transition: opacity 0.6s var(--curve);

            @media (max-width: 700px) {
              top: 0;
            }
          `}
        >
          {description}
        </div>
        <div
          css={css`
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
          `}
          onClick={() => {
            theme.setShowGallery(false);
            clearInterval(backgroundAnimation);
          }}
        >
          <Close
            css={css`
              position: relative;
              top: -2.5rem;
              width: 6rem;
              height: auto;

              @media (max-width: 700px) {
                top: 0;
              }

              &:hover {
                circle, path {
                  stroke: var(--primary);
                  transition: stroke 0s;
                }
              }

              circle, path {
                transition: stroke 0.6s var(--curve);
              }
            `}
          />
        </div>
        <div
          className="type--70"
          css={css`
            position: absolute;
            bottom: -3.5rem;
            left: 0;
            width: 50%;
            cursor: pointer;
            user-select: none;
            transition: color 0.6s var(--curve);

            @media (max-width: 700px) {
              bottom: 0;
            }

            &:hover {
              color: var(--primary);
            }
          `}
          onClick={() => embla?.scrollPrev() }
        >
          Prev
        </div>
        <div
          className="type--70"
          css={css`
            position: absolute;
            right: 0;
            bottom: -3.5rem;
            width: 50%;
            text-align: right;
            cursor: pointer;
            user-select: none;
            transition: color 0.6s var(--curve);

            @media (max-width: 700px) {
              bottom: 0;
            }

            &:hover {
              color: var(--primary);
            }
          `}
          onClick={() => embla?.scrollNext() }
        >
          Next
        </div>
      </div>
    </div>
  )
}
