import React, { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { ThemeContext } from './theme-provider';
import Close from '../svg/close.svg';

export default function ModalSubscription() {
  const [backgroundAnimation, setBackgroundAnimation] = useState(null);
  const [backgroundXOffset, setBackgroundXOffset] = useState(0);
  const [backgroundYOffset, setBackgroundYOffset] = useState(0);

  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (theme.showSubscription) {
      setBackgroundAnimation(setInterval(() => {
        setBackgroundXOffset(parseInt(Math.random() * (1920), 10));
        setBackgroundYOffset(parseInt(Math.random() * (1080), 10));
      }, 1000 / 16));
    }
  }, [theme.showSubscription]);

  return (
    <div
      css={css`
        display: ${(theme.showSubscription) ? 'block' : 'none'};
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('/images/background-static.png');
        will-change: background-position;
        z-index: 10;
      `}
      style={{
        backgroundPosition: `${backgroundXOffset}px ${backgroundYOffset}px`,
      }}
    >
      <div
        className="type--45"
        css={css`
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            position: relative;
            color: var(--black);
            background-color: var(--white);
            padding: calc(var(--gutter) * 3);

            @media (max-width: 700px) {
              width: calc(100% - (var(--gutter) * 2));
            }
          `}
        >
          <div
            css={css`
              position: absolute;
              top: var(--gutter);
              right: var(--gutter);
              cursor: pointer;

              &:hover {
                svg {
                  circle {
                    stroke: var(--primary);
                    transition: stroke 0s;
                  }

                  path {
                    stroke: var(--primary);
                    transition: stroke 0s;
                  }
                }
              }

              svg {
                @media (max-width: 700px) {
                  width: 6rem;
                }

                circle {
                  stroke: var(--black);
                  transition: stroke 0.6s var(--curve);
                }

                path {
                  stroke: var(--black);
                  transition: stroke 0.6s var(--curve);
                }
              }
            `}
            onClick={() => {
              theme.setShowSubscription(false)
              clearInterval(backgroundAnimation);
            }}
          >
            <Close />
          </div>
          <div
            css={css`
              text-transform: uppercase;
              text-align: center;
              margin-bottom: calc(var(--gutter) * 2);
            `}
          >Newsletter</div>
          <form
            action="//thesubstation.us5.list-manage.com/subscribe/post?u=21cfc09295377f72f03e377c6&amp;id=f0c98b414b"
            method="post"
          >
            <input
              css={css`
                width: 50vw;
                max-width: 640px;
                padding: 1rem;
                border-radius: 0;
                -webkit-appearance: none;
                border: 3px solid var(--black);
                margin-bottom: calc(var(--gutter) * 2);

                @media (max-width: 700px) {
                  width: 100%;
                }
              `}
              type="email"
              name="EMAIL"
              placeholder="enter your email here"
            /><br />
            <input
              css={css`
                display: block;
                border-radius: 0;
                -webkit-appearance: none;
                border: 3px solid var(--black);
                background-color: transparent;
                padding: 1rem 4rem 0.7rem;
                margin: 0 auto;
                cursor: pointer;
                transition: background-color 0.6s var(--curve);

                &:hover {
                  background-color: var(--primary);
                  transition: background-color 0s;
                }
              `}
              type="submit"
              value="Subscribe"
              name="subscribe"
            />
          </form>
        </div>
      </div>
    </div>
  )
}
