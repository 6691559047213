import React, { createContext, useState } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [showGallery, setShowGallery] = useState(false);
  const [initialGallerySlide, setInitialGallerySlide] = useState(0);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showSubscription, setShowSubscription] = useState(false);
  const [smallHeader, setSmallHeader] = useState(false);
  const [grayFooter, setGrayFooter] = useState(false);

  return (
    <ThemeContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        calendarOpen,
        setCalendarOpen,
        galleryImages,
        setGalleryImages,
        showGallery,
        setShowGallery,
        modalContent,
        setModalContent,
        modalTitle,
        setModalTitle,
        modalImage,
        setModalImage,
        showModal,
        setShowModal,
        initialGallerySlide,
        setInitialGallerySlide,
        showSubscription,
        setShowSubscription,
        smallHeader,
        setSmallHeader,
        grayFooter,
        setGrayFooter,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
