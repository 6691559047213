import React from 'react';
import { ThemeProvider } from './src/components/theme-provider';

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider>
      {element}
    </ThemeProvider>
  );
}

export const shouldUpdateScroll = (e) => {
  if (e.routerProps.location.hash === '' && e.prevRouterProps?.location.pathname !== window.location.pathname) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  if (e.routerProps.location.hash !== '' && e.pathname !== '/whats-on') {
    scrollToAnchor(e.routerProps.location)
  }

  return false;
}

function scrollToAnchor(location, mainNavHeight = 0) {
  if (location && location.hash) {
    const item = document.querySelector(`${location.hash}`).offsetTop

    window.scrollTo({
      top: item - mainNavHeight - 40,
      behavior: 'smooth',
    })
  }

  return true
}
