import React, { useContext, } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { css } from '@emotion/react';
import { ThemeContext } from './theme-provider';
import PortableText from '../components/portable-text';

export default function Menu({ reference }) {
  const theme = useContext(ThemeContext);

  const data = useStaticQuery(graphql`
    query MenuQuery {
      siteSettings: sanitySiteSettings(_id: { regex: "/(drafts\\\\.)?siteSettings/" }) {
        _rawSocials(resolveReferences: {maxDepth: 10})
      }
    }
  `)

  return (
    <div
      ref={reference}
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: var(--gray);
        cursor: ${(!theme.menuOpen) ? 'pointer' : 'auto'};
        mix-blend-mode: ${(theme.menuOpen) ? 'none' : 'exclusion'};
        transform: translateX(${(theme.menuOpen) ? '0' : 'calc(-100% + 1rem)'});
        transition: transform 0.6s var(--curve);
        z-index: 99;

        @media (max-width: 1080px) {
          right: 0;
          padding-top: 5rem;
          transform: translateX(${(theme.menuOpen) ? '0' : 'calc(-100%)'});
          mix-blend-mode: none;
          transition: transform 0s;
        }
      `}
      onClick={() => { if (!theme.menuOpen) theme.setMenuOpen(true) }}
    >
      <div
        className="type--20"
        css={css`
          position: absolute;
          right: 0;
          bottom: 5rem;
          transform-origin: 0 100%;
          transform: translateX(99%) rotate(90deg) translateX(-100%);

          @media (max-width: 1080px) {
            display: none;
          }
        `}
      >
        <div
          css={css`
            width: 20rem;
            height: 3rem;
            color: ${(theme.menuOpen) ? 'var(--gray)' : 'var(--black)'};
            text-align: center;
            background-color: var(--gray);
            padding: 0.75rem 1rem 0;
            transition: color 0.6s var(--curve);
            cursor: pointer;
          `}
          onClick={() => theme.setMenuOpen(!theme.menuOpen)}
        >
          Menu
          <div
            css={css`
              position: absolute;
              top: calc(50% - 1rem);
              left: calc(50% - 0.8rem);
              color: var(--black);
              opacity: ${(theme.menuOpen) ? 1 : 0};
              transform: rotate(-90deg);
              transition: opacity 0.6s var(--curve);
            `}
          >
            ←
          </div>
        </div>
      </div>
      <div
        className="type--70"
        css={css`
          padding: var(--gutter);

          @media (max-width: 1150px) {
            font-size: 6rem;
          }

          a {
            text-decoration: none;
            transition: color 0.6s var(--curve);

            &:hover {
              color: var(--primary);
              transition: color 0s;
            }
          }
        `}
      >
        <Link to="/" onClick={() => theme.setMenuOpen(false)}>Home</Link>/<br />
        <Link to="/whats-on" onClick={() => theme.setMenuOpen(false)}>What's On</Link>/<br />
        <Link to="/artists" onClick={() => theme.setMenuOpen(false)}>Artists</Link>/<br />
        <Link to="/about" onClick={() => theme.setMenuOpen(false)}>About</Link>/<br />
        <Link to="/visit" onClick={() => theme.setMenuOpen(false)}>Visit</Link>/<br />
        <Link to="/hire" onClick={() => theme.setMenuOpen(false)}>Hire</Link>/<br />
        <Link to="/support" onClick={() => theme.setMenuOpen(false)}>Support</Link>/
      </div>
      <div
        css={css`
          padding: var(--gutter);

          @media (max-width: 700px) {
            padding-bottom: calc(var(--gutter) * 3);
          }

          a {
            text-decoration: none;
            transition: color 0.6s var(--curve);

            &:hover {
              color: var(--primary);
              transition: color 0s;
            }
          }
        `}
      >
        <PortableText
          blocks={data.siteSettings._rawSocials}
        />
        <div
          css={css`
            margin-top: 1em;
          `}
        >
          <a
            target="_blank"
            href="https://tix.thesubstation.org.au/Account/Login"
          >My Account</a>
        </div>
      </div>
    </div>
  )
}
